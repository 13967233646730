<template>
    <div v-if="!loading">
        <zw-table ref="table"
                  tableName="attribute_set"
                  columnsPrefix="attribute-set.column."
                  :items="myProvider"
                  :fields="fields"
                  :filter="filter"
                  :selected="selected"
                  :sticky="false"
                  sticky-header="100%"
                  :actions-list="getActions()"
        >
            <template #cell(ordering)="row">
                <span v-for="order in row.item.ordering" class="m-2">
                    {{ order }}
                </span>
            </template>
            <template #cell(actions)="row">

                <b-dropdown class="mb-1"
                            left
                            variant="primary"
                            size="sm"
                >
                    <template #button-content>
                        {{ $t('common.label.actions') }}
                    </template>

                    <b-dropdown-item
                        @click="$root.$children[0].openModal('attribute-set-modal', {id: row.item.id},refreshTable,{width: '800px'})">
                        <font-awesome-icon class="mr-2" icon="edit"/>
                        {{ $t('common.title.edit') }}
                    </b-dropdown-item>
                    <b-dropdown-item
                        @click="deleteAttribute(row.item.id)">
                        <font-awesome-icon class="mr-2" icon="trash"/>
                        {{ $t('common.title.delete') }}
                    </b-dropdown-item>
                </b-dropdown>
            </template>
        </zw-table>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'SettingsAttributeSets',
    mixins: [commonSave, commonTable],
    data() {
        return {
            loading: true,
            attributeSets: [],
            saveFilters: true,
        }
    },
    methods: {
        ...mapGetters('Attributes', ['getAttributeSetsTable']),
        shown() {
            this.loading = false
            this.$root.afterSettingsUpdate()
        },
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Attributes/fetchAttributeSetsTable', 'getAttributeSetsTable');
        },
        deleteAttribute(id) {
            this.$removeConfirm('Attributes/deleteAttributeSet', id, this.refreshTable)
        },
        getActions() {
            let actions = [
                {
                    title: this.$t('common.button.create'),
                    icon: 'plus',
                    click: () => {
                        this.$root.$children[0].openModal('attribute-set-modal', {id: 0}, this.refreshTable, {width: '800px'})
                    }
                }
            ]
            return actions
        }
    },
    mounted() {
        this.shown()
    }
}
</script>